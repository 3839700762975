import firebase from 'firebase/app'
import 'firebase/firestore'

/**
 * OAuth functions getRefreshToken, setRefreshToken
 * 
 * @param service
 * 'rb': Redbooth
 * 'sheets': Google sheets
 */
export const getRefreshToken = async (user, service) => {
    try {
        var db = firebase.firestore()
    } catch (error) {
        initializeFirebaseApp()
        return await getRefreshToken(user, service)
    }
    
    return db.collection("users").doc(user.uid).get().then((doc) => {
        return doc.exists ? doc.data()[service+"RefreshToken"] : null
    })
}

export const setRefreshToken = async (user, refreshToken, service) => {

    if (refreshToken) {
        var db = firebase.firestore()
        const key  = service+'RefreshToken'
        return db.collection("users").doc(user.uid).set({
            [key]: refreshToken
        }, { merge: true }).catch((error) => {
            console.error("Error in setting rbRefreshToken for user: " + error)
        })
    } else {
        console.error(`Cannot set refreshToken of to database: ${refreshToken}`)
    }
}

export const setSharedDriveInfo = async (info, key) => {
    try {
        var db = firebase.firestore()
    } catch (error) {
        initializeFirebaseApp()
        console.error("No firebase app. Trying again")
        return await setSharedDriveInfo(info, key)
    }
    var i = {'properties':Object.assign({}, info)}
    return db.collection('sharedDriveInformation').doc(key).set(i, { merge: true }).catch((error) => {
        console.error(`Error in setting info: ${info} for key: ${key}: ${error}`)
    })
}

// TODO: Make more robust, usable by multiple organizations. 
export const getSharedDriveInfo = async () => {
    try {
        var db = firebase.firestore()
    } catch (error) {
        initializeFirebaseApp()
        return await getSharedDriveInfo()
    }
    const snapshot = await db.collection('sharedDriveInformation').get()
    var data = {}
    snapshot.docs.forEach((doc) => {
        data[doc.id] = doc.data()
    })
    return data
}

export const revokeRedbooth =  async (user) => {
    var db = firebase.firestore()
    return db.collection("users").doc(user.uid).update({
        rbRefreshToken: firebase.firestore.FieldValue.delete()
    }).catch((error) => {
        console.error("Error in setting rbRefreshToken for user: " + error)
    })
}

export const getSheetID = async (user) => {
    try {
        var db = firebase.firestore()
    } catch (error) {
        initializeFirebaseApp()
        return await getSheetID(user)
    }
    
    return db.collection("users").doc(user.uid).get().then((doc) => {
        return doc.exists ? doc.data()["sheetID"] : null
    })
}

export const setSheetId = async (user, sheetID) => {

    if (sheetID) {
        var db = firebase.firestore()
        return db.collection("users").doc(user.uid).set({
            "sheetID": sheetID
        }, { merge: true }).catch((error) => {
            console.error("Error in setting rbRefreshToken for user: " + error)
        })
    } else {
        console.error(`Cannot set sheetID of ${sheetID}`)
    }
    
}

// See: https://firebase.google.com/docs/web/setup
export const initializeFirebaseApp = () => {
    if (!firebase.apps.length) {
        try {
            var firebaseConfig = {
                apiKey: "AIzaSyCvE73UHThJicZXU6yuLTGbRzuhTUr4lRQ",
                authDomain: "hours-ninja.firebaseapp.com",
                databaseURL: "https://hours-ninja.firebaseio.com",
                projectId: "hours-ninja",
                storageBucket: "hours-ninja.appspot.com",
                messagingSenderId: "331029242809",
                appId: "1:331029242809:web:19477f7e77a379e3728c68",
                measurementId: "G-C2V79ZSJZ7"
            };
            firebase.initializeApp(firebaseConfig);
        } catch (e) {
            console.error(e)
        }
    }
    
}
