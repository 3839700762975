import React from 'react';
import PropTypes from 'prop-types'
import {Redirect} from "react-router-dom";
import {Button } from 'react-bootstrap';
import {getTokensFromCode,  getAuthCodeURI, refreshTokens} from '../backend/redbooth-auth'	
import {getRefreshToken,  setRefreshToken, revokeRedbooth, initializeFirebaseApp} from '../backend/database'
import {getSheetsAuthCodeURI} from '../backend/sheets-auth';
import DriveAPI from '../backend/drive-integration';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import 'firebase/auth'

// // Configure Firebase.
// const config = {
//   apiKey: "AIzaSyCvE73UHThJicZXU6yuLTGbRzuhTUr4lRQ",
//   authDomain: "hours-ninja.firebaseapp.com",
//   databaseURL: "https://hours-ninja.firebaseio.com",
//   projectId: "hours-ninja",
//   storageBucket: "hours-ninja.appspot.com",
//   messagingSenderId: "331029242809",
//   appId: "1:331029242809:web:19477f7e77a379e3728c68",
//   measurementId: "G-C2V79ZSJZ7"
// };
// firebase.initializeApp(config);

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ]
};

export default class AuthPage extends React.Component {

    static propTypes = {
        rbAuthCode: PropTypes.string
    }

    constructor(props) {
        super(props)
        this._isMounted = true
    }
    
    state = {
        needsGoogleSignIn: false,
        googleDriveAuthenticated: false,
        redboothAccessToken: null,
        needRedboothCode: true,
        user: null,
        mustRedirectToRoot: false,
    }

    componentDidMount() {
        initializeFirebaseApp()
        this.authSequence()
        this.checkGoogleAuth()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    sheetsAccessToken = () => localStorage.getItem('sheetsAccessToken')

    render() {
        if (this.state.mustRedirectToRoot) {
            return (<Redirect to="/" />)
        }
        if (this.isRedboothAuthenticated() && this.isGoogleAuthenticated()) {
            localStorage.setItem('rbAccessToken', this.state.redboothAccessToken)
            return (
                <Redirect to="/dashboard" />
            )
        }
        return (
            <div className="App">
                <header className="App-header">
                    <h1>Redbooth Data Feed</h1>
                    <div id="firebaseui-auth-container"></div>
                    {this.getCenter()}
                </header>
            </div>
        );
    }


    checkGoogleAuth = async () => {
        // console.log("checking google auth")
        const token = this.sheetsAccessToken()
        if (token === "undefined" || !token) {
            // console.log("No access token")
            this._isMounted && this.setState({googleDriveAuthenticated: false})
            return 
        }
        const driveAPI = new DriveAPI(token)
        const drives = await driveAPI.httpGetRequest("https://www.googleapis.com/drive/v3/drives")
        if (drives["error"] && drives["error"]["code"] !== 200) {
            // console.log(`Request to see drives:`)
            // console.log(drives)
            this._isMounted && this.setState({googleDriveAuthenticated: false})
        } else {
            this._isMounted && this.setState({googleDriveAuthenticated: true})
        }
    }

    isDriveAuthenticated = () => {
        return !!this.state.googleDriveAuthenticated && this.sheetsAccessToken() !== "undefined" && !!this.sheetsAccessToken()
    }

    isSignedIn = () => {
        return !this.state.needsGoogleSignIn
    }

    isRedboothAuthenticated = () => {
        // this.state.needRedboothCode === false
        
        return !!this.state.redboothAccessToken && !this.state.needRedboothCode
    }

    isGoogleAuthenticated = () => {
        return this.state.googleDriveAuthenticated === true
    }

    getCenter() {
        if (!this.isSignedIn()) {
            return (
                <div>
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
                </div>
            )
        }
        if (!this.isDriveAuthenticated()) {
            return (
                <div style={{'textAlign':'center', 'width':'100%', 'paddingTop':'10px'}}>
                    <Button variant="outline-primary" href={getSheetsAuthCodeURI()} >
                        <img alt="Google Drive" src="https://www.gstatic.com/images/branding/product/1x/drive_48dp.png" width='40'/>
                        Authorize Google Drive
                    </Button>
                </div>
            )
        }
        if (!this.isRedboothAuthenticated()) {
            return (
                <a type="button" id="authorize_redbooth" href={getAuthCodeURI()}>
                    <img alt="Redbooth" src="https://redbooth.com/api/wp-content/themes/redbooth_dev_portal/favicon.ico"/>
                    Authorize Redbooth
                </a>
            )
        }
        if (this.state.user) {
            return (
                <>
                    <div id="loader" style={{"paddingBottom":20}}>Loading...</div>
                    <Button variant="outline-danger" onClick={() => {
                        revokeRedbooth(this.state.user)
                    }}> Revoke Redbooth Access (Press if loading is stuck, then refresh) </Button>
                </>
            )   
        }
        return (
            <div id="loader" style={{"paddingBottom":20}}>Loading...</div>
        )
        
    }

    /* 
        Auth sequence:

        IF user is signed into google
            IF redbooth auth code has been passed by router in App.js
                GET redbooth refresh token and access token
                STORE refresh token in firebase
                DONE, set access token in state
            ELSE IF user has stored redbooth refresh token
                GET redbooth access token
                DONE, set access token in state
            ELSE
                Using state, trigger sequence to display the uri to get auth code.
                DONE
        ELSE sign into google, GOTO start
    */
    authSequence = async () => {
        firebase.auth().onAuthStateChanged((user) => {
            this._isMounted && this.setState({user: user})
            if (user) {
                this.authFlowWithUser(user)
            } else {
                // User not signed in. Do google sign in
                this._isMounted && this.setState({needsGoogleSignIn: true})
            }
        })
    }

    authFlowWithUser = async (user) => {
        if (!user) {
            console.error("No user signed in")
            return
        }

        // If the auth code is passed in the props, then the user has already gone through redbooth authentication step 2 (see backend/database.js). 
        if (this.props.rbAuthCode) {
            // console.log("Recieved auth code in props")
            this.handleAuthentication(user)
            return
        } 
        
        
        // Otherwise, try to get the refresh token from the database and exchange it for an access token.
        const refresh_token = await getRefreshToken(user, 'rb')
        const tokens = await refreshTokens(refresh_token)
        if (tokens) {
            const access_token = tokens.access_token
            const new_refresh_token = tokens.new_refresh_token
            
            if (access_token && new_refresh_token) {
                await setRefreshToken(user, new_refresh_token, 'rb')
                this.setRbAccessToken(access_token)
                this._isMounted && this.setState({needRedboothCode: false})
            } else {
                // If that doesn't work, step 2 is needed (having user click on redbooth link).
                this._isMounted && this.setState({needRedboothCode: true})
            }
            
        } else {
            // console.log("Do not have Refresh token")
            // If that doesn't work, step 2 is needed (having user click on redbooth link).
            this._isMounted && this.setState({needRedboothCode: true})
        }
        
    }

    handleAuthentication = async (user) => {
        const tokens = await getTokensFromCode(this.props.rbAuthCode)
        if (!tokens) {
            console.error("Could not get tokens")
            console.error(tokens)
            this._isMounted && this.setState({mustRedirectToRoot: true})
            return
        }

        const access_token = tokens.access_token
        const refresh_token = tokens.refresh_token

        // console.log(`Access: ${access_token}\nRefresh: ${refresh_token}`)
        if (!access_token || !refresh_token) {
            console.error(`Did not recieve access token or refresh token from handling redbooth authentication.\nAccess: ${access_token}\nRefresh: ${refresh_token}`)
        }
        if (access_token) {
            this.setRbAccessToken(access_token)
        }
        if (refresh_token) {
            await setRefreshToken(user, refresh_token, 'rb') // Save refresh token for next time
        }
        this._isMounted && this.setState({needRedboothCode: false})
    }

    setRbAccessToken = (token) => {
        localStorage.setItem('rbAccessToken',token)
        this._isMounted && this.setState({redboothAccessToken: token})
    }

}
