import {getRefreshToken} from './database'
const axios = require('axios');
export const client_id = "331029242809-vip1vi97lltnorqk1cdt4k9lsm1occvv.apps.googleusercontent.com"
export const client_secret = "gG9mZMTJnhhSTm6XSXKIi75x"
const redirect_uri = process.env.NODE_ENV === 'development' ? "http://localhost:3000/sheetsAuth" : "https://rb.spidrdesign.com"
const scope = 'https://www.googleapis.com/auth/drive'
export const getSheetsAuthCodeURI = () => "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=" + encodeURIComponent(scope) + "&response_type=code&client_id=" + encodeURIComponent(client_id) + "&redirect_uri="+encodeURIComponent(redirect_uri);


// TODO: Fix bad naming- used to only use sheets scope, now uses entire drive scope.
const auth_endpoint = "https://accounts.google.com/o/oauth2/token"

export const getAuthorizationCode = async () => {
    var uri = this.getSheetsAuthCodeURI();
    return axios.get(
        uri
    ).then((response) => {
        return response
    }).catch((error) => {
        console.error(error)
    })
}


export const getTokensFromCode = async (code) => {
    return axios.post(auth_endpoint, {
        client_id:client_id,
        client_secret:client_secret,
        code:code,
        grant_type:"authorization_code",
        redirect_uri:redirect_uri
    }).then((response) => {
        const access_token = response.data.access_token
        const refresh_token = response.data.refresh_token
        return { access_token, refresh_token }
    }).catch((error) => {
        console.error(error)
    })
}

export const refreshAccessToken = async (refresh_token) => {
    // console.log("Refresh token", refresh_token)
    return axios.post(auth_endpoint, {
        client_id:client_id,
        client_secret:client_secret,
        refresh_token:refresh_token,
        grant_type:"refresh_token",
    }).then((response) => {
        const access_token = response.data.access_token
        return access_token
    }).catch((error) => {
        console.error(error)
        localStorage.setItem("sheetsAccessToken", undefined)
    })
}

export const getFreshAccessToken = async (user) => {
    const refresh_token = await getRefreshToken(user, 'sheets')
    return await refreshAccessToken(refresh_token)
}

