import React, { Component } from 'react';

import './App.css';
import AuthPage from "./views/authentication"
import Dashboard from "./views/dash"
import SheetsAuth from "./views/sheets-authentication"
import GoogleAuth from "./views/google-authentication"
import { Switch, Route, useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export default class App extends Component {
  render() {
    return (
        <Switch>
          <Route exact path="/">
            <RouteBaseTraffic/> 
          </Route>
          <Route path='/sheetsAuth'>
            <Sheets/>
          </Route>
          <Route path="/auth">
            <Auth />
          </Route>
          <Route path="/googleAuth">
            <GoogleAuth />
          </Route>
          <Route path="/dashboard" render={(props) => 
            <Dashboard {...props}/>
          }/>
        </Switch>
      
    );
  }

  
}

function RouteBaseTraffic() {
  let query = useQuery();
  let code = query.get("code")

  if (query.get("scope")) {
    return (
      <SheetsAuth sheetsAuthCode={code} />
    )
  }
  return (
    <AuthPage rbAuthCode={code} />
  )
}
// You can think of these components as "pages"
// in your app.


function Auth() {
  let query = useQuery();
  return (
    <AuthPage rbAuthCode={query.get("code")} />
    
  );
}


function Sheets() {
  let query = useQuery();
  return (
    <SheetsAuth sheetsAuthCode={query.get("code")} />
  );
}

/**
 * http://localhost:3000/sheetsAuth?code=4/0QG4tubu3h8eZeHfaDT1UBee9pYGR9G5U-oHqMRe6G-Q_XAD6cRNc0Tlv55l_z-ykD3uTXYHW6DZCe2upA4JaCo&scope=https://www.googleapis.com/auth/drive
 */
