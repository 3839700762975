import React from 'react';
import {Card, Accordion, Alert} from 'react-bootstrap';
import PropTypes from 'prop-types';

import CSV from './step-three-options/csv.js';
import Sheets from './step-three-options/sheets.js';
import Drive from './step-three-options/drive.js';

export default class StepThree extends React.Component {
    
    render() {
        return (
            <>
                {this.getErrorAlert()}
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            Add new tab to a Google sheet
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Sheets user={this.props.user} csvElements={this.props.csvElements}/>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                            Update shared Google Drive folder
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <Drive user={this.props.user} csvElements={this.props.csvElements}/>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card> 
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="2">
                            Download as CSV
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <CSV csvElements={this.props.csvElements}/>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </>
            )
    }

    getErrorAlert = () => {
        if (!this.props.recievedErrorsWhileFetchingComments) {
            return (<></>) 
        }
        return (
            <Alert variant={'warning'}>
                During the export, issues were incountered with pulling some tasks. Please report this issue by opening the Inspect Elements tab, selecting "console," taking a screenshot of the error messages, and sending that screenshot to dev@spidr.design.  
            </Alert>
        )
    }
    

}


StepThree.propTypes = {
    csvElements: PropTypes.object,
    user: PropTypes.object,
    recievedErrorsWhileFetchingComments: PropTypes.bool,
}