import React from 'react';
import PropTypes from 'prop-types'
import {Redirect} from "react-router-dom";
import {getTokensFromCode, refreshAccessToken} from '../backend/sheets-auth'	
import {getRefreshToken,  setRefreshToken, initializeFirebaseApp} from '../backend/database'
import firebase from 'firebase/app'

export default class SheetsAuth extends React.Component {


    static propTypes = {
        sheetsAuthCode: PropTypes.string
    }
    
    state = {
        accessToken: null,
        needsGoogleSignIn: false,
    }

    componentDidMount() {
        initializeFirebaseApp()
        this.authSequence()
    }

    render() {
        if (this.isAuthenticated()) {
            return (
                <Redirect to={{ pathname: "/" }}/>
            )
        } else 
        if (this.state.needsGoogleSignIn) {
            return (
                <Redirect to={{pathname: "/auth"}}/>
            )
        }
        return (
            <div className="App">
                <header className="App-header">
                    <h1>Redbooth Data Feed</h1>
                    {this.getCenter()}
                </header>
            </div>
        );
    }

   
    getCenter = () => (<div id="loader">Loading...</div>)
    
    authSequence = async () => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.authFlowWithUser(user)
            } else {
                // User not signed in. Do google sign in
                this.setState({needsGoogleSignIn: true})
            }
        })
    }

    authFlowWithUser = async (user) => {
        if (!user) {
            console.error("No user signed in")
            return
        }

        if (this.props.sheetsAuthCode) {
            await this.handleAuthentication(user)
            return  
        }

        const refresh_token = await getRefreshToken(user, 'sheets')
        if (refresh_token) {
            const access_token = await refreshAccessToken(refresh_token)
            this.setSheetsAccessToken(access_token)
            return
        } 
        
        console.error("Couldn't properly authorize Google Sheets")
    }

    /**
     * Handle the case in which an auth code was passed into the application's URI
     * @param {object} user - The Google user
     */
    handleAuthentication = async (user) => {
        const {access_token, refresh_token} = await getTokensFromCode(this.props.sheetsAuthCode)
        if (!access_token || !refresh_token) {
            console.error(`Did not recieve access token or refresh token from handling sheets authentication.\nAccess: ${access_token}\nRefresh: ${refresh_token}`)
        }
        if (access_token) {
            this.setSheetsAccessToken(access_token)
        }
        if (refresh_token) {
            await setRefreshToken(user, refresh_token, 'sheets') // Save refresh token for next time
        }
    }

    logout = () => {
        localStorage.removeItem('sheetsAccessToken')
    }

    setSheetsAccessToken = (token) => {
        localStorage.setItem("sheetsAccessToken", token)
        this.setState({accessToken: token})
    }

    isAuthenticated = () => {
        return !!this.state.accessToken
    }


}
