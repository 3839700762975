import React from 'react';
import { CSVLink } from "react-csv";
import {InputGroup, FormControl} from 'react-bootstrap';
// import {Button, Card, Accordion, Container, InputGroup, Row, Col, Form, Navbar, Spinner, FormControl} from 'react-bootstrap';
import PropTypes from 'prop-types';


export default class CSV extends React.Component {
    defaultCSVPreferences =  {'name':'redbooth-export','separator':',','enclosingCharacter':'"'}

    state = {
        csvPreferences: JSON.parse(JSON.stringify(this.defaultCSVPreferences)),
    }
    
    render() {
        return this.getCSVUI();
    }

    getCSVUI = () => {
        return (
            <>  
                <label>Filename</label>
                <InputGroup className="mb-3">
                    <FormControl
                    placeholder={this.defaultCSVPreferences.name}
                    aria-label="Filename"
                    aria-describedby="basic-addon2"
                    onChange={(val) => {
                        const text = val.currentTarget.value
                        var pref = this.state.csvPreferences
                        if (text.length  > 0) {
                            pref["name"] = text
                            this.setState({csvPreferences: pref})
                        } else {
                            pref["name"] = this.defaultCSVPreferences.name
                            this.setState({csvPreferences: pref})
                        }
                    }}
                    />
                    <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">.csv</InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
                {[["separator","Separator (Delimiter, the characters separating columns. Try tab: \"\t\" or pi: \"π\")"], ["enclosingCharacter","Enclosing Character (The character that values are wrapped with)"]].map((k, idx) => {
                    return (
                        <div key={idx}> 
                        <label>{k[1]}</label>
                        <InputGroup className="mb-3">
                            <FormControl
                            placeholder={this.defaultCSVPreferences[k[0]]}
                            aria-label={k[0]}
                            onChange={(val) => {
                                const text = val.currentTarget.value
                                var pref = this.state.csvPreferences
                                if (text.length  > 0) {
                                    pref[k[0]] = text
                                    this.setState({csvPreferences: pref}) 
                                } else {
                                    pref[k[0]] = this.defaultCSVPreferences[k[0]]
                                    this.setState({csvPreferences: pref})
                                }
                            }}
                            />
                        </InputGroup>
                        </div>
                    )
                        
                })}
                <CSVLink 
                    data={this.props.csvElements['data']} 
                    headers={this.props.csvElements['headers']} 
                    filename={this.state.csvPreferences.name.endsWith(".csv") ? this.state.csvPreferences.name : this.state.csvPreferences.name + ".csv"} 
                    separator={this.state.csvPreferences.separator} 
                    enclosingCharacter={this.state.csvPreferences.enclosingCharacter}>
                    Start
                </CSVLink>
            </>
        )
    }
    
}

CSV.propTypes = {
    csvElements: PropTypes.object,
}