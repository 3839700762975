import React from 'react';
import {Form, Button} from 'react-bootstrap';
import PropTypes from 'prop-types';

export default class MultipleCheckboxControl extends React.PureComponent  {



    constructor() {
        super()

        this.state = {
            items: null,
            selectedItems: [],
            lastSelectedItem: null,
            isShiftDown: false,
        }

        this.listEl = null;


        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleSelectItem = this.handleSelectItem.bind(this);
        this.handleSelectStart = this.handleSelectStart.bind(this);      
    }

    componentDidMount() {
        document.addEventListener("keyup", this.handleKeyUp, false)
        document.addEventListener("keydown", this.handleKeyDown, false)
        this.listEl.addEventListener("selectstart", this.handleSelectStart, false)
        const items = this.props.options
        this.setState({items:items, selectedItems: items})
        this.alertParent(items)
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.handleKeyUp);
        document.removeEventListener("keydown", this.handleKeyDown);
        this.listEl.removeEventListener("selectstart", this.handleSelectStart);
    }

    handleSelectStart(e) {
        if (this.state.isShiftDown) {
            e.preventDefault();
        }
    }

    handleKeyUp(e) {
        if (e.key === "Shift" && this.state.isShiftDown) {
            this.setState({ isShiftDown: false });
        }
    }
    
    handleKeyDown(e) {
        if (e.key === "Shift" && !this.state.isShiftDown) {
            this.setState({ isShiftDown: true });
        }
    }

    handleSelectItem(value) {
        const nextValue = this.getNextValue(value);
        this.setState({ selectedItems: nextValue, lastSelectedItem: value });
        this.alertParent(nextValue)
    }

    getNextValue(value) {
        const { isShiftDown, selectedItems } = this.state;
        const hasBeenSelected = !selectedItems.includes(value);
      
        if (isShiftDown) {
          const newSelectedItems = this.getNewSelectedItems(value);
          // de-dupe the array using a Set
          const selections = [...new Set([...selectedItems, ...newSelectedItems])];
      
          if (!hasBeenSelected) {
            return selections.filter(item => !newSelectedItems.includes(item)); 
          }
      
          return selections;
        }
      
        // if it's already in there, remove it, otherwise append it
        return selectedItems.includes(value)
          ? selectedItems.filter(item => item !== value)
          : [...selectedItems, value];
    }

    getNewSelectedItems(value) {
        const { lastSelectedItem, items } = this.state;
        const currentSelectedIndex = items.findIndex(item => item === value); // TODO: SHOULD BE SOME UNIQUE ID OF THE OBJECT
        const lastSelectedIndex = items.findIndex(
          item => item === lastSelectedItem // TODO: SHOULD BE SOME UNIQUE ID OF THE OBJECT
        );
      
        return items
          .slice(
            Math.min(lastSelectedIndex, currentSelectedIndex),
            Math.max(lastSelectedIndex, currentSelectedIndex) + 1
          );
    }

    /**
     * Selects all checkboxes, unless all are selected already. In that case, deselects all checkboxes.
     */
    selectAll = () => {
        const next = this.allAreSelected() ? [] : this.state.items
        this.setState({selectedItems: next})
        this.alertParent(next)
    }

    /**
     * Alert the parent of a change in the checkbox selections.
     */
    alertParent = (selectedItems) => {
        this.props.onUpdate(selectedItems)
    }

    /**
     * Determines whether or not all checboxes are selected.
     * Returns bool, true if all boxes are selected and false otherwise.
     */
    allAreSelected = () => {
        return this.state.items && this.state.items.length === this.state.selectedItems.length
    }

    render () {
        const items = this.state.items ? this.state.items : this.props.options
        return (
            <ul ref={node => (this.listEl = node)} style={{paddingLeft:0}}>
                {items.map((option, idx) => (
                    <div key={idx} >
                        <Form.Check key={idx} id={`item-${idx}`} type="checkbox" label={option[this.props.property]} checked={this.state.selectedItems.includes(option)} onChange={() => this.handleSelectItem(option)} />
                    </div>
                ))}
                <Button variant="outline-primary" onClick={this.selectAll}>{this.allAreSelected() ? "Deselect all" : "Select all"}</Button>
            </ul>
        );
    }
}

MultipleCheckboxControl.propTypes = {
    options: PropTypes.array,
    onUpdate: PropTypes.func,
    defaultSelected: PropTypes.bool,
    property: PropTypes.string,
}