import React from 'react';
import {Button, InputGroup, Spinner, FormControl, Alert} from 'react-bootstrap';
import PropTypes from 'prop-types';
import GooglePicker from 'react-google-picker';
import {client_id} from '../../../backend/sheets-auth';

const developerKey = 'AIzaSyCvE73UHThJicZXU6yuLTGbRzuhTUr4lRQ'

export default class DrivePicker extends React.Component {

    state = {
        showsCookieAlert: false,
        validation: null,
    }

    componentDidMount() {
        const id = this.props.properties.id
        if (id) {
            this.onIDChange(id)
        }
    }


    render() {
        return this.getPickerRow()
    }

    documentDescription = (isDocument) => isDocument ? "sheet" : "folder"

    capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1)

    helpText = (isDocument) => {
        if (isDocument) {
            return "(Find in url: https://docs.google.com/spreadsheets/d/[spreadsheet ID here]/...)"
        }
        return "(Find in url after navigating into folder: https://drive.google.com/drive/u/0/folders/[folder ID here])"
    }
    getPickerRow = () => {
        const isDocument = this.props.isDocument
        const label = this.props.label
        const properties = this.props.properties
        var onChange = (data) => {
            if (data["action"] === "picked") {
                const allProperties = data["docs"][0]
                const id = allProperties["id"]
                this.onIDChange(id)
            }
            
        }
        return (
            <>
                <label style={{"fontWeight":"bolder"}}>{label}</label>
                <InputGroup className="mb-3">
                    <FormControl
                    placeholder={this.capitalizeFirstLetter(this.documentDescription(isDocument))+ " ID " + this.helpText(isDocument)}
                    aria-describedby="basic-addon2"
                    value={this.props.properties.id}
                    onChange={(data) => this.onIDChange(data.currentTarget.value)}
                    />
                    <InputGroup.Append>
                    {this.getPickerTool(isDocument, "Choose "+this.documentDescription(isDocument), onChange)}
                    </InputGroup.Append>
                </InputGroup> 
                {this.cookieAlert()}
                {this.getPropertiesLabel(properties, isDocument)}
            </>
        )
    }

    onIDChange = async (id) => {
        this.setState({'validation':null})
        const data = await this.props.driveAPI.getPropertiesFromID(id)
        const properties = data.properties
        const validation = data.validation
        this.setState({'validation':validation})
        this.props.onPropertiesChange(properties, this.canContinue())
    }

    getPropertiesLabel = (properties, isDocument) => {
        if (!this.props.properties.id || this.props.properties.id.length===0) {
            return (
                <label>No {this.documentDescription(isDocument)} selected</label>
            )
        }
        if (!this.state.validation) {
            return (
                <Spinner animation="border"/>
            )
        }
        if (!this.state.validation.isValid) {
            return (<label style={{'color':'#FF0000'}}>Invalid {this.capitalizeFirstLetter(this.documentDescription(isDocument))} ID</label>)
        }
        if (!this.matchesExpectedDocumentType()) {
            return (<label style={{'color':'#FF0000'}}>The inputted resource must be a {this.documentDescription(isDocument)}, recieved a different type.</label>)
        }
        return (
            <div>
                <label>Google {this.capitalizeFirstLetter(this.documentDescription(isDocument))}: <a href={properties.url} rel="noopener noreferrer" target="_blank">{properties.name}</a></label>
            </div>
        )
    }

    getPickerTool = (isDoc, buttonText, onChange) => {
        const viewId = isDoc ? "SPREADSHEETS" : "FOLDERS"
        var createPicker = isDoc ? null : (google, oauthToken) => this.folderCreatePicker(google, oauthToken, onChange)
        return (
            <GooglePicker clientId={client_id}
              developerKey={developerKey}
              onChange={data => onChange(data)}
              onAuthFailed={data => {
                //   console.log('on auth failed:', data)
                  this.setState({'showsCookieAlert':true}) // TODO: only show if actually a problem with cookies
                }}
              multiselect={false}
              navHidden={false}
              authImmediate={false}
              viewId={viewId}
              createPicker={createPicker}
        >
             <Button variant="outline-primary">{buttonText}</Button>
            </GooglePicker>
        )
    }

    folderCreatePicker = (google, oauthToken, onChange) => {
        const googleViewId = google.picker.ViewId.FOLDERS;
        const docsView = new google.picker.DocsView(googleViewId)
            .setIncludeFolders(true)
            .setMimeTypes('application/vnd.google-apps.folder')
            .setSelectFolderEnabled(true);

        const picker = new window.google.picker.PickerBuilder()
            .addView(docsView)
            .setOAuthToken(oauthToken)
            .setDeveloperKey(developerKey)
            .setCallback((data)=>{
              onChange(data)
            });

        picker.build().setVisible(true);
    }

    cookieAlert = () => {
        return this.state.showsCookieAlert ? (
            <Alert 
                variant='primary' 
                onClose={() => this.setState({'showsCookieAlert':false})}
                dismissible>
                    The Google picker unfortunately must run in a browser environment that allows cookies. For example, incognito mode will not work with this tool.
            </Alert>
        ) : (<></>)
    }

    matchesExpectedDocumentType = () => this.props.isDocument ? this.state.validation.isDocument : this.state.validation.isFolder
    
    canContinue = () => !!this.state.validation && this.state.validation.isValid && this.matchesExpectedDocumentType()



}

DrivePicker.propTypes = {
    label:PropTypes.string,
    isDocument:PropTypes.bool,
    properties:PropTypes.object,
    onPropertiesChange:PropTypes.func,
    driveAPI:PropTypes.object
}