import React from 'react';
import {Redirect} from 'react-router-dom';

// TODO: https://react-bootstrap.github.io/getting-started/introduction/#importing-components
import {Button,Container, Col, Navbar, Spinner } from 'react-bootstrap';
import {initializeFirebaseApp} from '../backend/database';
// import {initializeFirebaseApp, setSheetId, getSheetID, getSharedDriveInfo, setSharedDriveInfo} from '../backend/database';
import Stepper from 'react-stepper-horizontal';
import StepOne from './steps/step-one';
import StepTwo from './steps/step-two';
import StepThree from './steps/step-three';
import firebase from 'firebase/app'

export default class Dashboard extends React.Component {

    rbAccessToken = () => localStorage.getItem('rbAccessToken')
    sheetsAccessToken = () => localStorage.getItem('sheetsAccessToken')

    state = {
        currentStep: 0, // Keep track of step
        redboothExportPreferences: {}, // From step 1
        currentUser: null,
        csvElements: {},
        recievedErrorsWhileFetchingComments: false,
    };
    // Current step should be 0
    // csvElements should be {}

    componentDidMount() {
        this.authSequence()   
    }

    authSequence = async () => {
        initializeFirebaseApp()
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({currentUser: user})
            } else {
                // User not signed in. Do google sign in
                console.error("User isn't signed in")
            }
        })
    }

    render() {
        if (!this.rbAccessToken()) {
            return (
                <Redirect to='/auth'/>
            )
        }
        return (
            <Container>
                 <Navbar bg="light" expand="lg">
                    <Navbar.Brand>Redbooth Hours Dashboard</Navbar.Brand>
                    {this.getRestartButton()}
                </Navbar>
                <Col>
                    <Stepper steps={ [
                        {title: 'Set Download Options'}, 
                        {title: 'Download Data'}, 
                        {title: 'Save Data'}
                    ] } activeStep={ this.state.currentStep } circleFontSize={0}/>
                    <div style={{'height':'20px'}}/>
                    {this.getUIForStep(this.state.currentStep)}
                </Col>
            </Container>            
        );
    }

    finishStepOne = async (redboothExportPreferences) => {
        this.setState({
            redboothExportPreferences: redboothExportPreferences,
            currentStep:1,
        });
    }

    finishStepTwo = async (csvElements, recievedErrorsWhileFetchingComments) => {
        this.setState({
            csvElements: csvElements, 
            currentStep: 2,
            recievedErrorsWhileFetchingComments: recievedErrorsWhileFetchingComments
        });
    }

    getRestartButton = () => {
        if (this.state.currentStep === 0) {
            return
        }
        return (
            <Button variant='outline-danger' href={process.env.NODE_ENV === 'development' ? "http://localhost:3000" : "https://rb.spidrdesign.com"}>Restart</Button>
        )
    }

    getUIForStep = (step) => {
        if (!this.state.currentUser) {
            return (<Spinner animation="border" />)
        }
        switch (step) {
            case 0:
                return (<StepOne rbAccessToken={this.rbAccessToken()} segueNextStep={this.finishStepOne}/>)
            case 1:
                return (<StepTwo rbAccessToken={this.rbAccessToken()} redboothExportPreferences={this.state.redboothExportPreferences} segueNextStep={this.finishStepTwo}/>)
            case 2:
                return (<StepThree csvElements={this.state.csvElements} user={this.state.currentUser} recievedErrorsWhileFetchingComments={this.state.recievedErrorsWhileFetchingComments}/>)
            default:
                console.error(`Got case of unexpected step ${step}`)
                return 
        }
    }

}

/*
    wait = (timeout) => {
        return new Promise(resolve => {
            setTimeout(resolve, timeout);
        });
    }
*/