import {getRefreshToken, setRefreshToken,initializeFirebaseApp} from './database'
import firebase from 'firebase/app'

const proxy = "https://safe-dusk-70283.herokuapp.com/"
const axios = require('axios');

/**
 * Code here follows the guide on redbooth: https://redbooth.com/api/authentication/#auth-guide
 */

const auth_endpoint = "https://redbooth.com/oauth2/token"

/**
 * Step 1: Initial Setup, registering the application
 * 
 * For localhost:
 * Name: redbooth-data-feed
 * Client ID: f27f632b92666726d6cc6ab84406be42efaf48d7555168586520986dff5633f2
 * Client Secret: 79c27bb649d94a32cef517a1b280cf18f0b021585eedda2c364aec1a82fd1787
 * Return URI: http://localhost:3000
 * 
 * For public 
 * Name: redbooth-data-feed-public
 * Client ID: 4d25e4be43144b6ba29f2522afcc37ea5e6530e70380f9939953688b60825a4f
 * Client Secret: c974bc6f077fa3fc7632cf5d7c59676c25f8671545fc19e14495d69455da71a9
 * Return URI: https://rb.spidrdesign.com
 */

var client_id = ""
var client_secret = ""
var redirect_uri = ""

if (process.env.NODE_ENV === 'development') {
    client_id = "f27f632b92666726d6cc6ab84406be42efaf48d7555168586520986dff5633f2"
    client_secret = "79c27bb649d94a32cef517a1b280cf18f0b021585eedda2c364aec1a82fd1787"
    redirect_uri = "http://localhost:3000" 
} else {
    client_id = "4d25e4be43144b6ba29f2522afcc37ea5e6530e70380f9939953688b60825a4f"
    client_secret = "c974bc6f077fa3fc7632cf5d7c59676c25f8671545fc19e14495d69455da71a9"
    redirect_uri = "https://rb.spidrdesign.com"
}



export var redirect = redirect_uri



/**
 * Step 2: Getting the Authorization Code from the User
 * 
 * getAuthCodeURI is called by the Auth view (views/authentication.js) to set the href of a link to direct the user to.
 * TODO: Figure out why on authentication, Redbooth loads rather than redirecting immediately to our web app. 
 * 
 * When the code is recieved, the Router implementation in App.js passes the code directly to the Auth view (views/authentication.js), which then prompts step 3.
 */
export const getAuthCodeURI = () => "https://redbooth.com/oauth2/authorize?client_id=" + encodeURIComponent(client_id) + "&redirect_uri="+encodeURIComponent(redirect)+"&response_type=code";


// This code (as prompted on the guide), returns the HTML for the auth page on Redbooth's end. It is currently unused (I don't know whether or not that's a mistake).
export const getAuthorizationCode = async () => {
    var uri = this.getAuthCodeURI();
    return axios.get(
        proxy + uri
    ).then((response) => {
        return response
    }).catch((error) => {
        console.error(error)
    })
}


/**
 * Step 3: Getting the Access Token from Redbooth
 * 
 * accessTokenFromCode gets the Redbooth auth token and refresh token with the code returned to the Router from Redbooth in step 2. 
 */
export const getTokensFromCode = async (code) => {
    return axios.post(proxy + auth_endpoint, {
        client_id:client_id,
        client_secret:client_secret,
        code:code,
        grant_type:"authorization_code",
        redirect_uri:redirect
    }).then((response) => {
        const access_token = response.data.access_token
        const refresh_token = response.data.refresh_token
        return { "access_token": access_token, "refresh_token": refresh_token}
    }).catch((error) => {
        console.error(error)
    })
}

/**
 * Step 4: Refreshing the Access Token
 * 
 * Using a refresh token (stored in the database), get a fresh access token.
 */
export const refreshTokens = async (refresh_token) => {
    return axios.post(proxy + auth_endpoint, {
        client_id:client_id,
        client_secret:client_secret,
        refresh_token:refresh_token,
        grant_type:"refresh_token",
    }).then((response) => {
        const access_token = response.data.access_token
        const new_refresh_token = response.data.refresh_token
        return { "access_token": access_token, "new_refresh_token": new_refresh_token}
    }).catch((error) => {
        console.error("Error refreshing redbooth access token")
        console.error(error)
    })
}

export const refreshAccessToken = async () => {
    try {
        initializeFirebaseApp()
        const user = firebase.auth().currentUser
        if (user) {
            const refresh_token = await getRefreshToken(user, 'rb')
            const tokens = await refreshTokens(refresh_token)
            if (tokens) {
                const access_token = tokens.access_token
                const new_refresh_token = tokens.new_refresh_token
                await setRefreshToken(user, new_refresh_token, 'rb')
                return access_token 
            } else {
                console.error("Could not refresh tokens")
                console.error(tokens)
            }
        } else {
            throw new Error("No user signed in")
        }
    } catch (e) {
        console.error(e)
    }
    
}


