import React from 'react';
import DriveAPI from '../../../backend/drive-integration';
import SheetsAPI from '../../../backend/google-sheets-integration';
import {Spinner, ListGroup, Button, Alert} from 'react-bootstrap';
import {getSharedDriveInfo, setSharedDriveInfo} from '../../../backend/database';
import {getFreshAccessToken} from '../../../backend/sheets-auth';
import PropTypes from 'prop-types';
import DrivePicker from '../widgets/drive-item-picker'
var dateformat = require('dateformat');



export default class Drive extends React.Component {
    state = {
        driveFolderOptions: null,
        canContinueByObject: {},
        token: null,
        showsCookieAlert: false,
        driveAPI: null,
        loading: false, 
        finishedExporting: false,
        statusUpdate:"",
    }


    render () {
        return this.getPickerUI()
    }

    componentDidMount() {
        this.getTokens()
        this.fetchSharedDriveInfo()
    }

    getTokens = async () => {
        const user = this.props.user
        if (user) {
            const token = await getFreshAccessToken(user)
            this.setState({'driveAPI': new DriveAPI(token)})
        }
    }

    fetchSharedDriveInfo = async () => {
        const sharedDriveInfo = await getSharedDriveInfo()
        this.setState({driveFolderOptions:sharedDriveInfo})
        
    }

    allAreReadyToContinue = () => {
        const keys = Object.keys(this.state.canContinueByObject)
        const correctNumKeys = keys.length === Object.keys(this.state.driveFolderOptions).length
        const allCanContinue = keys.every((k) => this.state.canContinueByObject[k])
        return correctNumKeys && allCanContinue
    }

    getPickerUI = () => {
        if (this.state.driveFolderOptions && this.state.driveAPI) {
            return (
                <>
                    <ListGroup variant="flush">
                        {Object.keys(this.state.driveFolderOptions).map((key, idx) => {
                            var options = this.state.driveFolderOptions[key]
                            return (
                                <ListGroup.Item key={idx}>
                                    <DrivePicker 
                                        label={options.label} 
                                        isDocument={options.isDocument}
                                        properties={options.properties}
                                        onPropertiesChange={(properties, canContinue) => this.setDriveItemPropertiesForKey(key, properties, canContinue)}
                                        driveAPI={this.state.driveAPI}/>
                                </ListGroup.Item>
                                )
                        })}
                    </ListGroup>
                    {
                        this.getAlert()
                    }
                    {
                        this.getContinueButton()
                    }
                </>
                )
        }
        return (<Spinner animation="border"/>)
        
    }

    getContinueButton = () => {
        if (this.allAreReadyToContinue()) {
            if (this.state.loading) {
                return (<Button disabled={true}>Loading...</Button>)
            } else {
                return (
                    <Button onClick={() => {
                        this.doGoogleDriveProcess()
                        this.setState({loading:true})
                    }}>Export</Button>
                )
            }
        }
        return (<></>)
    }

    setDriveItemPropertiesForKey = (key, properties, canContinue) => {
        var items = this.state.driveFolderOptions
        items[key]["properties"] = properties

        var continueObj = this.state.canContinueByObject
        continueObj[key] = canContinue

        this.setState({"driveFolderOptions":items, "canContinueByObject": continueObj})
        
        if (canContinue) {
            setSharedDriveInfo(properties, key)
        }
    }



    getAlert = () => {
        if (this.state.finishedExporting) {
            return (
                <Alert variant="success" onClose={() => this.setState({finishedExporting:false})} dismissible>
                    <Alert.Heading>Done!</Alert.Heading>
                    <p>
                    The first tab of the given spreadsheet from columns A to Q has been updated to reflect the pulled date
                    <a href={this.state.driveFolderOptions.worker.properties.url} rel="noopener noreferrer" target="_blank"> Click here </a> to open the Google sheet.
                    </p>
                </Alert>
            )
        } else if (this.state.loading) {
            return (
                <div>
                    <Spinner animation="border" size="sm" /><label> {this.state.statusUpdate}</label>
                </div>
            )
        }
        return <></>
    }


    
    doGoogleDriveProcess = async () => {
        if (this.props.token === null) {
            console.error('Missing token for Google Drive')
        }
        const drive = this.state.driveAPI

        const headers = this.props.csvElements.headers
        const data = this.props.csvElements.data

        const o = this.state.driveFolderOptions

        const dateString = dateformat(new Date(), "mmm d, yyyy h:MM tt")
        const archiveName = o.worker.properties.name + ` [Archived on ${dateString}]`

        // Move files around

        this.setState({statusUpdate:"Archiving the current copy..."})
        await drive.rename(o.worker.properties.id, archiveName)
        await drive.move(o.worker.properties.id, o.archive.properties.id)
        this.setState({statusUpdate:"Copying the template..."})
        const copiedTemplate = await drive.copy(o.template.properties.id)
        const newWorkerID = copiedTemplate.id
        this.setState({statusUpdate:"Renaming the template..."})
        await drive.rename(newWorkerID, o.worker.properties.name)


        this.setState({statusUpdate:"Updating default options..."})
        // Set default to updated sheet
        var driveOptions = this.state.driveFolderOptions
        const newWorker = await drive.getPropertiesFromID(newWorkerID)
        const newWorkerProperties = newWorker.properties
        driveOptions.worker.properties = newWorkerProperties
        setSharedDriveInfo(driveOptions.worker.properties, "worker")


        this.setState({statusUpdate:"Clearing first tab of the spreadsheet..."})
        // Delete unwanted template cells from copy
        const token = await getFreshAccessToken(this.props.user)
        const sheet = new SheetsAPI(token, null, newWorkerID)
        await sheet.clearRange("A:Q")

        this.setState({statusUpdate:"Writing to the spreadsheet..."})
        const writeRequest = await sheet.writeFirstTab(data, headers)

        // Write new data to the copied sheet
        this.setState({loading: false, driveFolderOptions: driveOptions, finishedExporting: true})
    }
}



Drive.propTypes = {
    user: PropTypes.object,
    csvElements: PropTypes.object,
}