import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar';
import RedboothAPI from '../../backend/redbooth-integration';
var events = require('events');


export default class StepTwo extends React.Component {
    

    state = {
        progressPercentage: 0,
        eventEmitter: new events.EventEmitter(),
        rb: null,
        excludedWorkspaces: [],
        recievedErrorsWhileFetchingComments: false, 
    }

    componentDidMount() {
        this.start()
    }
    render() {
        if (this.state.rb) {
            return this.getProgressBar();
        }
        return (<label>loading...</label>)
    }


    start = async () => {
        const rb = new RedboothAPI(this.props.rbAccessToken, this.state.eventEmitter)
        this.state.eventEmitter.on('progressUpdate', this.progressUpdateListener);
        this.state.eventEmitter.on('recievedErrorsWhileFetchingComments', this.errorListener);
        this.setState({rb: rb})
        const {headers, data} = await rb.downloadClassicCSV(
            this.props.redboothExportPreferences.usesDateRange, 
            this.props.redboothExportPreferences.startDate, 
            this.props.redboothExportPreferences.endDate,
            this.props.redboothExportPreferences.includedProjects
        )
        this.props.segueNextStep({'data':data, 'headers':headers}, this.state.recievedErrorsWhileFetchingComments)
    }

    getProgressBar = () => {
        return (
            <>
                <label>Tip: Make sure to keep this tab open, as closing the tab will end the task. </label>
                <div style={{'height':'10px'}}/>
                <label style={{"fontWeight":"bold"}}>{this.state.rb.message}</label>
                <ProgressBar animated now={this.state.progressPercentage} /> 
            </>
        );
    }

    progressUpdateListener = () => {
        this.setState({progressPercentage: this.state.rb.progressUpdate})
    }

    errorListener = () => {
        this.setState({recievedErrorsWhileFetchingComments: true})
    }

    
}

StepTwo.propTypes = {
    rbAccessToken: PropTypes.string.isRequired,
    redboothExportPreferences: PropTypes.object.isRequired,
    segueNextStep: PropTypes.func,
}