import { refreshAccessToken } from "./redbooth-auth";
const axios = require("axios");
const proxy = "https://safe-dusk-70283.herokuapp.com/";
const api_endpoint = "https://redbooth.com/api/3/";
const moment = require("moment");

export default class RedboothAPI {
  constructor(auth_token, event_emitter) {
    this.instance = this.getInstance(auth_token);
    this.progressUpdate = 0;
    this.message = "Initializing Download...";
    this.event_emitter = event_emitter;
  }

  getInstance = (token) => {
    return axios.create({
      baseURL: proxy + api_endpoint,
      timeout: 20000,
      headers: { Authorization: "Bearer " + token },
      validateStatus: () => true,
    });
  };

  httpGetRequest = async (route, parameters) => {
    var wasUnauthorized = false;
    const result = await this.instance
      .get(route, { params: parameters })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else if (res.status === 401) {
          wasUnauthorized = true;
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
      });

    if (result) {
      return result;
    } else if (wasUnauthorized) {
      console.log("Trying again");
      const access_token = await refreshAccessToken();
      this.instance = this.getInstance(access_token);
      return await this.httpGetRequest(route, parameters);
    }
  };

  getUserList = async () => await this.httpGetRequest("users", {});

  getCommentsForTask = async (task_id) =>
    await this.httpGetRequest("comments", {
      target_type: "Task",
      target_id: task_id,
    });

  getAllProjects = async (includesArchivedProjects) => {
    const options = includesArchivedProjects ? { archived: "true,false" } : {};
    return await this.httpGetRequest("projects", options);
  };

  getAllOrganizations = async () =>
    await this.httpGetRequest("organizations", {});

  getAllTasksForTaskList = async (task_list_id) =>
    await this.httpGetRequest("tasks", {
      archived: "true,false",
      task_list_id: task_list_id,
    });

  getAllTaskListsForProjectID = async (project_id) =>
    await this.httpGetRequest("task_lists", {
      archived: "true,false",
      project_id: project_id,
    });

  getAllTaskListsForProjectIDs = async (project_ids) => {
    var task_lists = [];
    await this.asyncForEach(project_ids, async (project_id, idx) => {
      var task_lists_for_id = await this.getAllTaskListsForProjectID(
        project_id
      );
      task_lists = task_lists.concat(task_lists_for_id);
    });
    return task_lists;
  };

  getTagName = async (element_id) => {
    const response = await this.httpGetRequest(`tags/${element_id}`);
    return response.name;
  };

  getTagMatchingRegex = async (element_ids, regex) => {
    let matches = [];
    for (let idx = 0; idx < element_ids.length; idx++) {
      const id = element_ids[idx];
      const name = await this.getTagName(id);
      if (new RegExp(regex).test(name)) matches.push(name);
    }
    if (matches.length === 0) {
      return ""
    } else if (matches.length === 1) {
      return matches[0];
    }
    return "Error: multiple sow tags";
  };

  getDataFromTaskComments = async (
    uses_date_range,
    from_date,
    to_date,
    taskComments,
    user_list,
    project_list,
    organization_list,
    task,
    task_lists
  ) => {
    // Filter out the commments that don't use time tracking
    if (taskComments) {
      var time_tracked_comments = taskComments.filter((comment) => {
        return comment.time_tracking_on && comment.minutes > 0;
      });
    } else {
      return [];
    }

    if (uses_date_range && time_tracked_comments) {
      const within_date_range = time_tracked_comments.filter((c) => {
        var time_tracked_date = new Date(c.time_tracking_on);
        time_tracked_date = new Date(
          time_tracked_date.getTime() +
            time_tracked_date.getTimezoneOffset() * 1000 * 60
        );
        return from_date <= time_tracked_date && time_tracked_date <= to_date;
      });
      time_tracked_comments = within_date_range;
    }

    // Load the current comments with other accessory fields we want for the spreadsheet.
    const loaded_comments = await Promise.all(
      time_tracked_comments.map(async (comment, idx) => {
        var c = comment;
        c["test"] = "Yes";

        // Month
        var time_tracked_date = new Date(c.time_tracking_on);
        time_tracked_date = new Date(
          time_tracked_date.getTime() +
            time_tracked_date.getTimezoneOffset() * 1000 * 60
        );
        const m =
          time_tracked_date.getMonth() +
          1 +
          " - " +
          time_tracked_date.toLocaleString("default", { month: "long" });
        c["month"] = moment(time_tracked_date).format("MM - MMMM");

        // Date: time_tracking_on

        const project = project_list.find(
          (project) => project.id === c.project_id
        );

        // Organization
        const organization = organization_list.find(
          (organization) => organization.id === project.organization_id
        );
        c["organization_name"] = organization.name;

        // Project
        c["project_name"] = project.name;

        // Task name
        c["task_name"] = task.name;
        // Task ID
        c["task_id"] = task.id;

        c["sow"] = await this.getTagMatchingRegex(
          task.tag_ids,
          "([0-9]{4}_.*)|(NonSOW)"
        );

        const task_list_id = task.task_list_id;
        c["task_list_id"] = task_list_id;
        try {
          // Task List name
          const task_list = task_lists.find((list) => list.id === task_list_id);
          c["task_list_name"] = task_list.name;
        } catch (e) {
          console.error(
            `could not find task list for id: ${task.task_list_id}`
          );
          c["task_list_name"] = "";
        }

        try {
          const commenter = user_list.find((user) => user.id === c.user_id);
          // User (The commenter's name)
          const name = commenter.first_name + " " + commenter.last_name;
          c["person_name"] = name;
          // Person (The commenter's username)
          const username = commenter.username;
          c["username"] = username;
        } catch (e) {
          c["person_name"] = `Former Spidr ${c.user_id}`;
          c["username"] = `Former Spidr ${c.user_id}`;
        }

        // Time in hours
        const minutes = c.minutes;
        const raw_hours = minutes / 60;
        c["hours"] = raw_hours;
        const hours = Math.ceil(raw_hours * 2.0) / 2;
        c["billable_hours"] = hours;

        // Duration
        const rounded_hours = Math.floor(hours);

        // Description: body

        // Comment transition
        const assignees = task.assigned_user_ids
          .map((uid) => user_list.find((user) => user.id === uid).email)
          .join(" \n");
        c["comment_transition"] = assignees;

        // Task current status
        c["task_status"] = task.status;

        // Task due date (very unsure)
        c["task_due_date"] = task.due_on;

        return c;
      })
    );
    return loaded_comments;
  };

  asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  getCSVData = async (uses_date_range, from_date, to_date, project_ids) => {
    const user_list = await this.getUserList();
    const project_list = await this.getAllProjects(true);
    const organization_list = await this.getAllOrganizations();
    var task_list = [];

    const task_lists = await this.getAllTaskListsForProjectIDs(project_ids);
    var all_data = [];

    await this.asyncForEach(task_lists, async (tl, idx) => {
      this.progressUpdate = (100 * idx) / task_lists.length;
      this.message = `Pulling tasklists from workspaces: ${idx + 1} of ${
        task_lists.length
      }`;
      this.event_emitter.emit("progressUpdate");
      if (tl) {
        const task_list_id = tl.id;
        const tasks_for_task_list = await this.getAllTasksForTaskList(
          task_list_id
        );
        if (tasks_for_task_list && tasks_for_task_list.length > 0) {
          task_list = task_list.concat(tasks_for_task_list);
        }
      } else {
        console.log("Could not get task_list");
      }
    });

    await this.asyncForEach(task_list, async (task, idx) => {
      this.progressUpdate = (100 * idx) / task_list.length;
      this.event_emitter.emit("progressUpdate");
      this.message = `Scraping comments from tasks: ${idx + 1} of ${
        task_list.length
      }`;
      const task_id = task.id;

      /**TODO: SCREEN FOR PROJECT */

      var creation_date = new Date(task.created_at * 1000);
      // creation_date = new Date(creation_date.getTime() + creation_date.getTimezoneOffset() * 1000 * 60)

      var update_date = new Date(task.updated_at * 1000);
      // update_date = new Date(update_date.getTime() + update_date.getTimezoneOffset() * 1000 * 60)

      // console.log(creation_date, update_date)
      if (
        task.comments_count > 0 &&
        !(creation_date > to_date) &&
        !(update_date < from_date)
      ) {
        const comments = await this.getCommentsForTask(task_id);
        if (!comments) {
          console.error("Got undefined comment for task:");
          console.error(task);
          this.event_emitter.emit("recievedErrorsWhileFetchingComments");
        } else {
          const processed_comments = await this.getDataFromTaskComments(
            uses_date_range,
            from_date,
            to_date,
            comments,
            user_list,
            project_list,
            organization_list,
            task,
            task_lists
          );
          all_data = all_data.concat(processed_comments);
        }
      }
    });
    return all_data.sort(
      (a, b) => new Date(a.time_tracking_on) - new Date(b.time_tracking_on)
    );
  };

  downloadClassicCSV = async (
    uses_date_range,
    from_date,
    to_date,
    project_ids
  ) => {
    const headers = [
      { label: "Month", key: "month" },
      { label: "Date", key: "time_tracking_on" },
      { label: "Organization", key: "organization_name" },
      { label: "Project", key: "project_name" },
      { label: "Project ID", key: "project_id" },
      { label: "Task", key: "task_name" },
      { label: "Task ID", key: "task_id" },
      { label: "Task List", key: "task_list_name" },
      { label: "Task List ID", key: "task_list_id" },
      { label: "User", key: "person_name" },
      { label: "Person", key: "username" },
      { label: "Tracked hours", key: "hours" },
      { label: "Description", key: "body" },
      { label: "Comment Transition", key: "comment_transition" },
      { label: "Task Current Status", key: "task_status" },
      { label: "Due date", key: "task_due_date" },
      { label: "Billable Hours", key: "billable_hours" },
      { label: "SOW ID", key: "sow" },
    ];

    const data = await this.getCSVData(
      uses_date_range,
      from_date,
      to_date,
      project_ids
    );
    console.log(data);
    return { headers, data };
  };
}
