import SheetsAPI from '../../../backend/google-sheets-integration';
import {setSheetId, getSheetID} from '../../../backend/database';
import {getFreshAccessToken} from '../../../backend/sheets-auth';
import React from 'react';
import {Button, Spinner, InputGroup, FormControl, Alert} from 'react-bootstrap';
import PropTypes from 'prop-types';
import DrivePicker from '../widgets/drive-item-picker'
import DriveAPI from '../../../backend/drive-integration';
var events = require('events');

export default class Sheets extends React.Component {

    state = {
        isValidGoogleSheetID: false,
        properties: {},
        driveAPI: null,
        editUrl: null,

        isExportingToSheets: false,
        finishedExporting: false,
    }

    render() {
        return this.getGoogleSheetsUI()
    }

    componentDidMount() {
        this.getTokens()
    }

    getTokens = async () => {
        const user = this.props.user
        if (user) {
            const token = await getFreshAccessToken(user)
            const drive = new DriveAPI(token)
            this.setState({token: token, driveAPI:drive})
            this.attemptRestoreGoogleSheetID()
        }
    }

    getGoogleSheetsUI = () => {
        if (this.state.properties.id || this.state.properties.id === "") {
            return (
                <>  
                    <DrivePicker 
                        label={"Google Sheet ID (Find in url: https://docs.google.com/spreadsheets/d/[spreadsheet ID here]/...)"} 
                        isDocument={true}
                        properties={this.state.properties}
                        onPropertiesChange={(properties, canContinue) => this.saveDriveProperties(properties, canContinue)}
                        driveAPI={this.state.driveAPI}/>
                    {this.sheetStatus()}
                </>
            )
        } else {
            return (<Spinner animation="border"/>)
        }
        

    }

    saveDriveProperties = async (properties, canContinue) => {
        this.setState({properties: properties, isValidGoogleSheetID:canContinue})
        if (canContinue) {
            setSheetId(this.props.user, properties.id)
        }
    }

    startSingleSheetProcess = async () => {
        const token = await getFreshAccessToken(this.props.user)
        const sh = new SheetsAPI(token, null, this.state.properties.id)
        this.setState({isExportingToSheets:true})
        await sh.exportToGoogleSheets(this.props.csvElements.data, this.props.csvElements.headers)
        this.setState({isExportingToSheets:false, finishedExporting: true})
    }

    attemptRestoreGoogleSheetID = async () => {
        const prevSheetID = await getSheetID(this.props.user)
        this.setState({properties: {'name':null,'id':prevSheetID ? prevSheetID : "",'url':null}})
    }

    
    sheetStatus = () => {
        if (this.state.isValidGoogleSheetID) {
            return (<>
                {this.getAlert()}
                <Button
                    disabled={this.state.isExportingToSheets}
                    onClick={this.startSingleSheetProcess}>{this.state.isExportingToSheets ? "Loading..." : "Export"}
                </Button>
                
            </>)
        }
    }

    getAlert = () => {
        if (this.state.finishedExporting) {
            return (
                <Alert variant="success" onClose={() => this.setState({finishedExporting:false})} dismissible>
                    <Alert.Heading>Done!</Alert.Heading>
                    <p>
                    A new tab has been created at the end of the Google sheet with the exported data inside. To view the Google sheet, press the link above. 
                    </p>
                </Alert>
            )
        }
        return <></>
    }

}

Sheets.propTypes = {
    user: PropTypes.object,
    csvElements: PropTypes.object,
}