import React from 'react';
import {Button, InputGroup, Row, Col, Form, Card, Spinner} from 'react-bootstrap';
// import {Button, Card, Accordion, Container, InputGroup, Row, Col, Form, Navbar, Spinner, FormControl} from 'react-bootstrap';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import MultipleCheckboxControl from './widgets/multiple-checkbox';
import RedboothAPI from '../../backend/redbooth-integration';
var events = require('events');

export default class StepOne extends React.Component {
    
    state = {
        usesDateRange: true,
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        includedProjects: null,
        includeArchivedProjects: false,
        projects: null,
        isLoadingProjects: true,
    }


    componentDidMount() {
        this.getProjects(this.state.includeArchivedProjects)
    }

    getProjects = async (includeArchivedProjects) => {
        const eventEmitter = new events.EventEmitter()
        const rb = new RedboothAPI(this.props.rbAccessToken, eventEmitter)
        var allProjects = await rb.getAllProjects(includeArchivedProjects)
        allProjects = allProjects.map(proj => {return {
            "id":proj.id,
            "name":proj.name
        }}).sort((p1, p2) => p1.name > p2.name ? 1 : -1)
        this.setState({projects:allProjects, isLoadingProjects:false})
    }

    render() {
        return (
        <>
            <Card body>
                <Form.Check 
                    checked={this.state.usesDateRange} 
                    type="checkbox"
                    label="Use Date Range"
                    style={{'fontWeight':'bold'}}
                    onChange={() => this.toggleState('usesDateRange')} 
                />
                {this.getDatePickers()}
            </Card>
            <div style={{'height':'10px'}}/>
            <Card body>
                {this.getProjectPicker()}
            </Card>
            <div style={{'height':'10px'}}/>
            {this.getNextButton()}
        </>
        );
    }

    getProjectPicker = () => {
        return (
            <>
                <label style={{'fontWeight':'bold'}}>Select workspaces</label>
                {this.getCheckboxes()}
            </>
        )
    }

    getArchivedSwitch = () => {
        return (
            <>
                <div/>
                <Form.Check 
                    checked={this.state.includeArchivedProjects}
                    type="switch"
                    id="custom-switch"
                    label="Include archived workspaces"
                    onChange={(event) => {
                        this.setState({includeArchivedProjects: !this.state.includeArchivedProjects, isLoadingProjects: true})
                        this.getProjects(!this.state.includeArchivedProjects)
                    }}
                />
                <div style={{'height':'10px'}}/>
            </>
        )
    }

    getCheckboxes = () => {
        if (this.state.projects && !this.state.isLoadingProjects) {
            return (<MultipleCheckboxControl 
                options={this.state.projects}
                defaultSelected={true} 
                onUpdate={(selected) => 
                    {
                        this.setState({"includedProjects":selected.map(obj => obj.id)})
                    }
                }
                property={"name"}
            />)
        }
        return (<div><Spinner animation="border" /></div>)
    }

    onExcludeProjectChange = (vals) => {
        this.setState({excludedProjects: vals})
    }
    

    /**
     * Reverses a boolean value related to the given key.
     * @param {string} key name of the key to flip the value of (expected boolean value related to the key)
     */
    toggleState = (key) => {
        this.setState({[key]:!this.state[key]})
    }
    
    /**
     * Generate the part of the UI for the calendar date pickers. 
     * Checks the usesDateRange variable for whehter or not the interface is necessary.
     * Returns React.Component the start and end date pickers. 
     */
    getDatePickers = () => {
        if (!this.state.usesDateRange) {
            return
        }
        return (
            <Row xs={2} md={4} lg={6}>
                {this.getDatePicker("Start Date", this.state.startDate, this.changeStartDate)}
                {this.getDatePicker("End Date", this.state.endDate, this.changeEndDate)}
            </Row>
        )
    }

    /**
     * Generate a date picker placed below a label in a column. 
     * Returns React.Component, the date picker.
     * @param {string} label - the description above the date picker
     * @param {bool} currentDate - the current selected date
     * @param {func} onChange - the function to execute upon a date change
     */
    getDatePicker = (label, currentDate, onChange) => {
        return (
            <Col>
                <label>{label}</label>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <DatePicker
                                selected={currentDate}
                                onChange={onChange}
                            />
                    </InputGroup.Prepend>
                </InputGroup>
            </Col>
        )
    }

    /**
     * Modify the startDate state variable
     * @param {Date} date - the date object to change the startDate to
     */
    changeStartDate = date => {
        this.setState({
            startDate: date
        });
    };

    /**
     * Modify the endDate state variable
     * @param {Date} date - the date object to change the endDate to
     */
    changeEndDate = date => {
        this.setState({
            endDate: date
        });
    };

    /**
     * Checks if there are at least some selected radio buttons for the selection of projects. 
     * Returns bool, true if there are some selected radio buttons, false if no buttons are selected.
     */
    radioButtonsAreSelected = () => {
        return this.state.includedProjects && this.state.includedProjects.length !== 0
    }

    /**
     * Retrieves a button for the interface, enabled when there are radio buttons for the projects selected. 
     * On click, the button runs the segueNextStep function passed in the props
     * Returns React.Component, the button 
     */
    getNextButton = () => {
        const enabled = this.radioButtonsAreSelected()
        return (
            <Button variant="primary" disabled={!enabled} onClick={() => {
                this.props.segueNextStep({
                    "usesDateRange": this.state.usesDateRange,
                    "startDate": this.state.startDate,
                    "endDate": this.state.endDate,
                    "includedProjects": this.state.includedProjects,
                })
            }}>Next</Button>
        )
    }

}


StepOne.propTypes = {
    rbAccessToken: PropTypes.string.isRequired,
    segueNextStep: PropTypes.func,
}