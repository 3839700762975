const axios = require('axios');
const proxy = "https://safe-dusk-70283.herokuapp.com/"
const api_endpoint = "https://www.googleapis.com/drive/v3/"

export default class DriveAPI {
    
    constructor(auth_token) {
        this.instance = this.getInstance(auth_token)
        this.proxyInstance = this.getInstance(auth_token, proxy)
    }

    getInstance = (token, proxy="") => axios.create({
        baseURL: proxy + api_endpoint,
        timeout: 20000,
        headers: {'Authorization': 'Bearer '+token},
        validateStatus: () => true
    });

    httpGetRequest = async (route, parameters) => {
        return await this.instance.get(route, {'params': parameters})
        .then((res) => {
            return res.data
        }).catch((error) => {
            console.error(error);
            console.error(error.errors);
            console.error(error.message);
        })
    }

    httpPatchRequest = async (route, body) => {
        return await this.instance.patch(route, body).then((res) => {
            return res.data
        }).catch((error) => {
            console.error(error)
        })
    }
    
    httpPostRequest = async (route, body) => {
        return await this.instance.post(route, body).then((res) => {
            return res.data
        }).catch((error) => {
            console.error(error)
        })
    }

    asyncForEach = async (array, callback) =>  {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    move = async (fileID, newFolderID) => {
        const res = await this.httpGetRequest(`files/${fileID}`, {'fields':'parents'})
        const parents = res.parents
        const move = await this.httpPatchRequest(`files/${fileID}`, {'addParent': newFolderID, 'removeParent':parents, 'fields':'id, parents'})
        return move
    }

    rename = async (fileID, newFilename) => {
        return await this.httpPatchRequest(`files/${fileID}`, {'name':newFilename})
    }

    copy = async (fileID) => {
        const copy = await this.httpPostRequest(`files/${fileID}/copy`, {'fields':'id, webViewLink, name'})
        return copy
    }



    getChildIDFromName = async (folderID, filename) => {
        const res = await this.httpGetRequest('files', {'q':`name = '${filename}' and '${folderID}' in parents`})
        try {
            if (res.files.length > 1) {
                console.error("Multiple files with same name")
            }
            return res.files[0].id
        } catch (error) {
            console.error(error)
        }
    }

    getNameFromID = async (id) => {
        const res = await this.httpGetRequest(`files/${id}`, {'fields':'name'})
        try {
            return res.name
        } catch (error) {
            console.error(error)
        }
    }

    getPropertiesFromID = async (id) => {
        const res = await this.httpGetRequest(`files/${id}`, {'fields':'name,webViewLink'})
        const url = res.webViewLink
        const isValid = !res.error
        const isDocument = !!url && url.includes("docs.google.com/spreadsheets")
        const isFolder = !!url && url.includes("drive.google.com/drive/folders")
        try {
            return {
                'properties':{
                    'id':id,
                    'name':res.name,
                    'url':url
                },
                'validation':{
                    'isDocument':isDocument,
                    'isFolder':isFolder,
                    'isValid':isValid,
                }
            }
        } catch (error) {
            console.error(`Got error while trying to get properties from ID ${error}`)
        }
    }


}

