const axios = require('axios');
const proxy = "https://safe-dusk-70283.herokuapp.com/"
const api_endpoint = "https://sheets.googleapis.com/v4/spreadsheets/"

export default class SheetsAPI {
    
    constructor(auth_token, event_emitter, spreadsheetID) {
        this.spreadsheetID = spreadsheetID 
        this.event_emitter = event_emitter
        this.instance = this.getInstance(auth_token)
        this.proxyInstance = this.getInstance(auth_token, proxy)
    }

    getInstance = (token, proxy="") => axios.create({
        baseURL: proxy + api_endpoint + this.spreadsheetID,
        timeout: 300000,
        headers: {'Authorization': 'Bearer '+token},
        validateStatus: () => true
    });

    httpGetRequest = async (route, parameters) => {
        return await this.instance.get(route, {'params': parameters})
        .then((res) => {
            return res.data
        }).catch((error) => {
            console.error(error);
            console.error(error.errors);
            console.error(error.message);
        })
    }

    httpPostRequest = async (route, body) => {
        return await this.instance.post(route, body).then((res) => {
            return res.data
        }).catch((error) => {
            console.error(error)
        })
    }

    asyncForEach = async (array, callback) =>  {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    isValidSheetsID = async () => {
        return await this.proxyInstance.get("")
        .then((res) => {
            return ({
                "title":res.data.properties.title,
                "url":res.data.spreadsheetUrl
            })
        }).catch((error) => {
            console.error(error);
            return false
        })
    }
    
    jsonsHeaders = ((array) => Array.from(
        array.map(json => Object.keys(json))
        .reduce((a, b) => new Set([...a, ...b]), [])
    ));

    isJsons = ((array) => Array.isArray(array) && array.every(
        row => (typeof row === 'object' && !(row instanceof Array))
    ));

    getHeaderValue = (property, obj) => {
        const foundValue = property
          .replace(/\[([^\]]+)]/g, ".$1")
          .split(".")
          .reduce(function(o, p, i, arr) {
            // if at any point the nested keys passed do not exist, splice the array so it doesnt keep reducing
            if (o[p] === undefined) {
              arr.splice(1);
            } else {
              return o[p];
            }
          }, obj);
        // if at any point the nested keys passed do not exist then looks for key `property` in object obj
        return (foundValue === undefined) ? ((property in obj) ? obj[property] : '') : foundValue;
      }

    jsons2arrays = (data, headers) => {
        headers = headers || this.jsonsHeaders(data);
      
        // allow headers to have custom labels, defaulting to having the header data key be the label
        let headerLabels = headers;
        let headerKeys = headers;
        if (this.isJsons(headers)) {
          headerLabels = headers.map((header) => header.label);
          headerKeys = headers.map((header) => header.key);
        }
      
        const d = data.map((object) => headerKeys.map((header) => this.getHeaderValue(header, object)));
        return [headerLabels, ...d];
      };

    getWriteRequestBody = (data, headers, sheetID) => {
        const arr2d = this.jsons2arrays(data, headers)
        const formattedRows = this.getFormattedRows(arr2d)
        return {
            "requests":[

                {
                "appendDimension": {
                    "sheetId": sheetID,
                    "dimension": "ROWS",
                    "length": formattedRows.length
                  }
                },
               {
                  "updateCells":{
                     "rows":formattedRows,
                     "fields":"*",
                     "start":{
                        "sheetId":sheetID,
                        "rowIndex":0,
                        "columnIndex":0
                     }
                  }
               }
            ]
         }
        
    }

    getOverwriteRequestBody = (data, headers, sheetID, currentRows) => {
        const arr2d = this.jsons2arrays(data, headers)
        const formattedRows = this.getFormattedRows(arr2d)
        const appendRows = formattedRows.length - currentRows
        var reqs = []
        if (appendRows > 0) {
            reqs.push({
                "appendDimension": {
                    "sheetId": sheetID,
                    "dimension": "ROWS",
                    "length": appendRows
                  }
                })
        }
        reqs.push({
            "updateCells":{
               "rows":formattedRows,
               "fields":"*",
               "start":{
                  "sheetId":sheetID,
                  "rowIndex":0,
                  "columnIndex":0
               }
            }
         })
        return {
            "requests":reqs
         }
        
    }

    getFormattedRows = (arr2d) => {
        return  arr2d.map((arr) => {
            return {
                "values":arr.map((val) => this.getIndividualValue(val))
            }
        })
    }
    
    getIndividualValue = (val) => {
        const key = typeof val === 'string' ? "stringValue" : "numberValue"
        return {
            "userEnteredValue":{
                [key]:val
            },
            "userEnteredFormat":{
                "numberFormat":{
                    "type":"TEXT"
                }
            }
        }
    }

    writeToSheetID = async (data, headers, sheetID) => {
        const requestbody = this.getWriteRequestBody(data, headers, sheetID)
        const write = await this.httpPostRequest(":batchUpdate", requestbody)
        return write
    }


    clearRange = async (range) => {
        const clearRange = await this.httpPostRequest("/values/"+range+":clear")
        return clearRange
    }

    getDataFirstTab = async () => {
        const info = await this.httpGetRequest()
        const firstSheet = info["sheets"][0]
        const maxRows = firstSheet["basicFilter"]["range"]["endRowIndex"]
        const sheetId = firstSheet["properties"]["sheetId"]
        return {
            "maxRows":maxRows,
            "sheetId":sheetId
        }
    }

    writeFirstTab = async (data, headers) => {
        const firstTab = await this.getDataFirstTab()
        const rows = firstTab.maxRows
        const sheetId = firstTab.sheetId
        const requestbody = this.getOverwriteRequestBody(data, headers, sheetId, rows)
        const write = await this.httpPostRequest(":batchUpdate", requestbody)
        return write
    }

    
    exportToGoogleSheets = async (data, headers) => {
        const newSheet = await this.httpPostRequest(":batchUpdate", {
            "requests": [
                {
                "addSheet": {
                    "properties": {
                    "title": "Redbooth Export " + Math.floor(Date.now() / 1000),
                    "gridProperties": {
                        "rowCount": 1,
                        "columnCount": 20
                    },
                    "tabColor": {
                        "red": 1.0,
                        "green": 0.0,
                        "blue": 0.0
                    }
                    }
                }
                }
            ]
        })
        const sheetID =  newSheet.replies.find((operation) => Object.keys(operation).includes("addSheet")).addSheet.properties.sheetId // also could be index

        return await this.writeToSheetID(data, headers, sheetID)
        
    }

}

